import axios from 'axios';

const createTweet = async (filename, setTweetContent) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-tweet`, { filename }, { withCredentials: true });
    setTweetContent(prev => ({ ...prev, [filename]: response.data.tweet }));
  } catch (error) {
    console.error('Error creating tweet:', error);
    // You might want to show an error message to the user here
  }
};

export default createTweet;