import axios from 'axios';

const CreateSocialContent = () => {
  const createSocialContent = async (filename, setCreatingContent) => {
    setCreatingContent(prev => ({ ...prev, [filename]: true }));
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-social-content`, { filename }, { withCredentials: true });
      if (response.data.success) {
        alert(`Social content creation started. Job ID: ${response.data.jobId}`);
      }
    } catch (error) {
      console.error('Error creating social content:', error);
      alert('Failed to create social content');
      setCreatingContent(prev => ({ ...prev, [filename]: false }));
    }
  };

  return createSocialContent;
};

export default CreateSocialContent;