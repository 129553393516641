import axios from 'axios';

const createLinkedin = async (filename, setLinkedinContent) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-linkedin`, { filename }, { withCredentials: true });
    setLinkedinContent(prev => ({ ...prev, [filename]: response.data.tweet }));
  } catch (error) {
    console.error('Error creating tweet:', error);
    // You might want to show an error message to the user here
  }
};

export default createLinkedin;