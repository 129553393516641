import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "./MyProfile.css";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

function MyProfile() {
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    address: "",
    businessCompany: "",
    isPro: false,
    usedFreeTrial: false,
    freeTrialAttempts: 0,
    stripeSubscriptionId: "",
    stripeCurrentPeriodEnd: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkAuthAndFetchProfile();
    handlePaymentConfirmation();
  }, []);

  const checkAuthAndFetchProfile = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/check-auth`);
      await fetchProfile();
    } catch (error) {
      console.error("Authentication check failed:", error);
      navigate("/login");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentConfirmation = async () => {
    const urlParams = new URLSearchParams(location.search);
    const sessionId = urlParams.get("session_id");

    if (sessionId) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/confirm-payment`,
          { sessionId }
        );
        console.log("Payment confirmation response:", response.data);

        if (response.data.success) {
          await fetchProfile(); // Refresh the profile to get updated subscription info
          // You might want to show a success message to the user
        }
      } catch (error) {
        console.error("Error confirming payment:", error);
        // Handle the error, maybe show an error message to the user
      }
    }
  };

  const fetchProfile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/profile`);
      console.log(response.data, "RESPONSE");
      setProfile(response.data);
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const updateField = async (name, value) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/profile`, { [name]: value });
    } catch (error) {
      console.error("Error updating profile:", error);
      // Optionally, show an error message
    }
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    updateField(name, value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  const handleSubscribe = async () => {
    const stripe = await stripePromise;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/create-checkout-session`
      );
      const session = response.data;

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      console.log(result, "RESULT");
      if (result.data) {
        try {
          await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/save-checkout-result`,
            result.data
          );
          console.log("Checkout result saved successfully");
        } catch (error) {
          console.error("Error saving checkout result:", error);
        }
      }

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/cancel-subscription`);
      alert(
        "Your subscription has been cancelled. You will continue to have access until the end of your current billing period."
      );
      await fetchProfile(); // Refresh the profile data
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      alert("Failed to cancel subscription. Please try again later.");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'Not available' : date.toLocaleDateString();
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="my-profile-container">
      <h1 className="profile-title">My Profile</h1>
      <div className="profile-content">
        <form className="profile-form">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={profile.firstName}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyPress={handleInputKeyPress}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={profile.lastName}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyPress={handleInputKeyPress}
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={profile.address}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyPress={handleInputKeyPress}
            />
          </div>
          <div className="form-group">
            <label htmlFor="businessCompany">Business Company</label>
            <input
              type="text"
              id="businessCompany"
              name="businessCompany"
              value={profile.businessCompany}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyPress={handleInputKeyPress}
            />
          </div>
        </form>

        <div className="subscription-section">
          <h2>Subscription</h2>
          {profile.isPro ? (
            <div className="pro-subscription">
              <div className="pro-status">
                <p>
                  You are a Pro user. Enjoy voice cleaning and post creation
                  features!
                </p>
                <span className="pro-badge">PRO</span>
              </div>
              <div className="subscription-info">
                <p>
                  Your subscription will renew on:{' '}
                  {formatDate(profile.stripeCurrentPeriodEnd)}
                </p>
                <button
                  onClick={handleCancelSubscription}
                  className="cancel-button"
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          ) : (
            <div className="upgrade-section">
              <p>
                Upgrade to Pro for $10/month to access voice cleaning and post
                creation features.
              </p>
              <div className="trial-info">
                <p>
                  Free Trial Status:{" "}
                  <span
                    className={profile.usedFreeTrial ? "used" : "available"}
                  >
                    {profile.usedFreeTrial ? "Used" : "Available"}
                  </span>
                </p>

                <p>
                  Free Trial Attempts:{" "}
                  <span className="attempts">
                    {profile.freeTrialAttempts} / 3
                  </span>
                </p>
                {profile.stripeCurrentPeriodEnd && new Date(profile.stripeCurrentPeriodEnd) > new Date() && (
                  <p>
                    Pro Version End Date:{" "}
                    <span className="pro-end-date">
                      {new Date(profile.stripeCurrentPeriodEnd).toLocaleDateString()}
                    </span>
                  </p>
                )}
              </div>
              <button onClick={handleSubscribe} className="subscribe-button">
                Subscribe to Pro
              </button>
            </div>
          )}
        </div>

        {profile.isPro && (
          <div className="pro-features">
            <h2>Pro Features</h2>
            <div className="feature-buttons">
              <button
                onClick={() => {
                  /* Implement voice cleaning logic */
                }}
                className="feature-button"
              >
                <i className="fas fa-magic"></i> Clean Voice Recordings
              </button>
              <button
                onClick={() => {
                  /* Implement post creation logic */
                }}
                className="feature-button"
              >
                <i className="fas fa-pen-fancy"></i> Create Posts
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyProfile;
