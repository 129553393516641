const handleSocialContentSeek = (e, fileUrl, setSocialContentPlayback) => {
    const audioElement = document.getElementById(`social-audio-${fileUrl}`);
    if (audioElement) {
      const progressBar = e.currentTarget;
      const rect = progressBar.getBoundingClientRect();
      const clickPosition = (e.clientX - rect.left) / rect.width;
      const seekTime = clickPosition * audioElement.duration;
      audioElement.currentTime = seekTime;
      setSocialContentPlayback(prev => ({
        ...prev,
        [fileUrl]: { ...prev[fileUrl], currentTime: seekTime }
      }));
    }
  };

  export default handleSocialContentSeek;