const stopRecording = (setIsRecording, setAudioURL, mediaRecorderRef, audioChunksRef, timerRef) => {
  if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
    mediaRecorderRef.current.stop();
    clearInterval(timerRef.current);
    setIsRecording(false);

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioURL(audioUrl);
      audioChunksRef.current = [];
    };
  }
};

export default stopRecording;