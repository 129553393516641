import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const useSocketSetup = (setRecordings, setTranscribing, setProcessing, setSummarizing, setCreatingContent) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_BACKEND_URL}`);
    setSocket(newSocket);

    newSocket.on('transcriptionComplete', ({ filename, transcription }) => {
      setRecordings(prevRecordings => 
        prevRecordings.map(recording => 
          recording.filename === filename 
            ? { ...recording, transcription, transcriptionText: transcription } 
            : recording
        )
      );
      setTranscribing(prev => ({ ...prev, [filename]: false }));
    });

    newSocket.on('noiseRemovalComplete', ({ oldFilename, newFilename, newFileUrl }) => {
      setRecordings(prevRecordings => 
        prevRecordings.map(recording => 
          recording.filename === oldFilename 
            ? { ...recording, filename: newFilename, fileUrl: newFileUrl } 
            : recording
        )
      );
      setProcessing(prev => ({ ...prev, [oldFilename]: false }));
    });

    newSocket.on('noiseRemovalFailed', ({ filename }) => {
      setProcessing(prev => ({ ...prev, [filename]: false }));
    });

    newSocket.on('summarizationComplete', ({ filename, summary }) => {
      setRecordings(prevRecordings => 
        prevRecordings.map(recording => 
          recording.filename === filename 
            ? { ...recording, summary } 
            : recording
        )
      );
      setSummarizing(prev => ({ ...prev, [filename]: false }));
    });

    newSocket.on('summarizationFailed', ({ filename }) => {
      setSummarizing(prev => ({ ...prev, [filename]: false }));
    });

    newSocket.on('socialContentComplete', ({ filename, socialContent, socialContentType }) => {
      setRecordings(prevRecordings => 
        prevRecordings.map(recording => 
          recording.filename === filename 
            ? { ...recording, socialContent, socialContentType } 
            : recording
        )
      );
      setCreatingContent(prev => ({ ...prev, [filename]: false }));
    });

    newSocket.on('socialContentFailed', ({ filename }) => {
      setCreatingContent(prev => ({ ...prev, [filename]: false }));
    });

    return () => newSocket.disconnect();
  }, [setRecordings, setTranscribing, setProcessing, setSummarizing, setCreatingContent]);

  return socket;
};

export default useSocketSetup;
