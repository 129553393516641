const HandleSeek = (setPlaybackStates) => {
  const handleSeek = (e, fileUrl) => {
    const audioElement = document.getElementById(`audio-${fileUrl}`);
    if (audioElement) {
      const progressBar = e.currentTarget;
      const rect = progressBar.getBoundingClientRect();
      const clickPosition = (e.clientX - rect.left) / rect.width;
      const seekTime = clickPosition * audioElement.duration;
      audioElement.currentTime = seekTime;
      setPlaybackStates(prev => ({
        ...prev,
        [fileUrl]: { 
          ...prev[fileUrl], 
          currentTime: seekTime,
          duration: audioElement.duration // Ensure duration is updated
        }
      }));
    }
  };

  return handleSeek;
};

export default HandleSeek;