import axios from 'axios';

const handleTitleSave = async (filename, newTitle, setRecordings) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/add-title`, 
      { filename, title: newTitle },
      { withCredentials: true }
    );

    if (response.data.success) {
      console.log('Title saved successfully');
      // Update the recordings state
      setRecordings(prevRecordings => 
        prevRecordings.map(recording => 
          recording.filename === filename ? { ...recording, title: newTitle } : recording
        )
      );
    } else {
      console.error('Failed to save title');
    }
  } catch (error) {
    console.error('Error saving title:', error);
  }
};

export default handleTitleSave;