import axios from 'axios';

const summarizeAudio = async (filename, setSummarizing) => {
  try {
    setSummarizing(prev => ({ ...prev, [filename]: true }));
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/summarize-audio`, { filename }, { withCredentials: true });
    if (response.data.success) {
      console.log('Summarization job started successfully');
      // The actual summarization will be completed asynchronously on the server
      // The socket connection will notify when it's done
    } else {
      console.error('Failed to start summarization job');
    }
  } catch (error) {
    console.error('Error starting summarization job:', error);
  } finally {
    // Note: We're not setting summarizing to false here because the job is asynchronous
    // It will be set to false when the socket receives the completion event
  }
};

export default summarizeAudio;