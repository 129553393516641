import axios from 'axios';

const saveRecording = async (audioURL, setRecordings, showMessage) => {
    if (!audioURL) {
        console.error('No audio to save');
        showMessage('No audio to save', 'error');
        return;
    }

    try {
        const response = await fetch(audioURL);
        const blob = await response.blob();
        const formData = new FormData();
        formData.append('audio', blob, 'recording.wav');

        const saveResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/save-recording`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        if (saveResponse.data.success) {
            console.log('Recording saved successfully');
            showMessage('Recording saved successfully', 'success');
            // Fetch updated recordings
            const fetchResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/recordings`, { withCredentials: true });
            setRecordings(fetchResponse.data);
            return true; // Indicate successful save
        } else {
            console.error('Failed to save recording');
            showMessage('Failed to save recording', 'error');
            return false; // Indicate failed save
        }
    } catch (error) {
        console.error('Error saving recording:', error);
        showMessage('Error saving recording', 'error');
        return false; // Indicate failed save
    }
};

export default saveRecording;