import axios from 'axios';

const transcribeAudio = async (filename, setTranscribing) => {
    try {
      setTranscribing(prev => ({ ...prev, [filename]: true }));
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/transcribe-audio`, { filename }, { withCredentials: true });
      if (response.data.success) {
        console.log('Transcription job started successfully');
        // The actual transcription will be completed asynchronously on the server
        // The socket connection will notify when it's done
      } else {
        console.error('Failed to start transcription job');
      }
    } catch (error) {
      console.error('Error starting transcription job:', error);
    } finally {
      // Note: We're not setting transcribing to false here because the job is asynchronous
      // It will be set to false when the socket receives the completion event
    }
};

export default transcribeAudio;