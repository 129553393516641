import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './App.css';  // Add this line
import './CreateUser.css';

function CreateUser() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-user`, { email, password });
      alert('User created successfully. Please login.');
      navigate('/login');
    } catch (error) {
      console.error('User creation failed:', error);
      setError(error.response?.data?.error || 'User creation failed. Please try again.');
    }
  };

  return (
    <div className="app-container">
      <header className="app-header">
        <h1>Voice Notes Recorder</h1>
      </header>
      <main className="app-main">
        <div className="create-user-container">
          <h2>Create Account</h2>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit" className="create-user-button">Create Account</button>
          </form>
          <p>Already have an account? <Link to="/login">Login</Link></p>
        </div>
      </main>
    </div>
  );
}

export default CreateUser;