const startRecording = async (setIsRecording, setRecordingDuration, timerRef, mediaRecorderRef, audioChunksRef, maxDuration) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        clearInterval(timerRef.current);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setRecordingDuration(0);

      timerRef.current = setInterval(() => {
        setRecordingDuration((prevDuration) => {
          if (prevDuration >= maxDuration) {
            clearInterval(timerRef.current);
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            return maxDuration;
          }
          return prevDuration + 1;
        });
      }, 1000);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  export default startRecording;