import { useState } from 'react';
import axios from 'axios';

const RemoveBackgroundNoise = () => {
	const [processing, setProcessing] = useState({});

	const removeBackgroundNoise = async (filename) => {
		setProcessing(prev => ({ ...prev, [filename]: true }));
		try {
			const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/remove-background-noise`, { filename }, { withCredentials: true });
			if (response.data.success) {
				alert(`Background noise removal started. Job ID: ${response.data.jobId}`);
			}
		} catch (error) {
			console.error('Error removing background noise:', error);
			alert('Failed to remove background noise');
			setProcessing(prev => ({ ...prev, [filename]: false }));
		}
	};

	return { removeBackgroundNoise, processing };
};

export default RemoveBackgroundNoise;