import axios from 'axios';

const handleNoteSave = async (filename, newNote, setRecordings, setEditingNote) => {
  try {
    console.log('Saving note for filename:', filename);
    console.log('New note content:', newNote);

    if (!filename) {
      throw new Error('Filename is required');
    }

    const noteToSave = newNote === undefined || newNote === null ? '' : newNote;

    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/add-note`, 
      { filename, note: noteToSave },
      { 
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data.success) {
      console.log('Note saved successfully');
      setRecordings(prevRecordings => 
        prevRecordings.map(recording => 
          recording.filename === filename ? { ...recording, note: noteToSave } : recording
        )
      );
      setEditingNote(null);
    } else {
      throw new Error(response.data.message || 'Failed to save note');
    }
  } catch (error) {
    console.error('Error saving note:', error);
    throw error;
  }
};

export default handleNoteSave;