const HandleTimeUpdate = (setPlaybackStates) => {
  const handleTimeUpdate = (fileUrl) => {
    const audioElement = document.getElementById(`audio-${fileUrl}`);
    if (audioElement) {
      setPlaybackStates(prev => ({
        ...prev,
        [fileUrl]: { 
          ...prev[fileUrl], 
          currentTime: audioElement.currentTime,
          duration: audioElement.duration
        }
      }));
    }
  };

  return handleTimeUpdate;
};

export default HandleTimeUpdate;