import axios from 'axios';

const HandleDelete = (setRecordings, showMessage) => {
  const handleDelete = async (filename) => {
    if (window.confirm('Are you sure you want to delete this recording?')) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/delete-recording/${filename}`, { withCredentials: true });
        if (response.data.success) {
          console.log('Recording deleted successfully');
          setRecordings(prevRecordings => {
            const updatedRecordings = prevRecordings.filter(recording => recording.filename !== filename);
            console.log('Updated recordings:', updatedRecordings);
            return updatedRecordings;
          });
          showMessage('Recording deleted successfully', 'success');
        } else {
          console.error('Failed to delete recording');
          showMessage('Failed to delete recording', 'error');
        }
      } catch (error) {
        console.error('Error deleting recording:', error);
        showMessage('Error deleting recording', 'error');
      }
    }
  };

  return handleDelete;
};

export default HandleDelete;