import handleSocialContentSeek from './handleSocialContentSeek';

const createSocialContentHandlers = (setSocialContentPlayback) => {
  const handleSocialContentPlay = (fileUrl) => {
    const audioElement = document.getElementById(`social-audio-${fileUrl}`);
    if (audioElement) {
      audioElement.play().then(() => {
        setSocialContentPlayback(prev => ({
          ...prev,
          [fileUrl]: { 
            isPlaying: true, 
            currentTime: audioElement.currentTime,
            duration: audioElement.duration
          }
        }));
      }).catch(error => {
        console.error("Error playing audio:", error);
      });
    }
  };

  const handleSocialContentPause = (fileUrl) => {
    const audioElement = document.getElementById(`social-audio-${fileUrl}`);
    if (audioElement) {
      audioElement.pause();
      setSocialContentPlayback(prev => ({
        ...prev,
        [fileUrl]: { ...prev[fileUrl], isPlaying: false }
      }));
    }
  };

  const handleSocialContentTimeUpdate = (fileUrl) => {
    const audioElement = document.getElementById(`social-audio-${fileUrl}`);
    if (audioElement) {
      setSocialContentPlayback(prev => ({
        ...prev,
        [fileUrl]: { 
          ...prev[fileUrl], 
          currentTime: audioElement.currentTime,
          duration: audioElement.duration
        }
      }));
    }
  };

  const handleSeek = (e, fileUrl) => {
    handleSocialContentSeek(e, fileUrl, setSocialContentPlayback);
  };

  return { 
    handleSocialContentPlay, 
    handleSocialContentPause, 
    handleSocialContentTimeUpdate, 
    handleSocialContentSeek: handleSeek
  };
};

export default createSocialContentHandlers;
