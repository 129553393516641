import axios from 'axios';

const FetchRecordings = () => {
  const fetchRecordings = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/recordings`, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error('Error fetching recordings:', error);
      throw error;
    }
  };

  return fetchRecordings;
};

export default FetchRecordings;