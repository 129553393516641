const HandlePlay = (setCurrentAudio, setPlaybackStates) => {
  const handlePlay = (fileUrl) => {
    setCurrentAudio(fileUrl);
    setPlaybackStates(prev => ({
      ...prev,
      [fileUrl]: { 
        isPlaying: true, 
        currentTime: prev[fileUrl]?.currentTime || 0,
        duration: prev[fileUrl]?.duration || 0
      }
    }));
  };

  return handlePlay;
};

export default HandlePlay;