const HandlePause = (setPlaybackStates) => {
  const handlePause = (fileUrl) => {
    setPlaybackStates(prev => ({
      ...prev,
      [fileUrl]: { ...prev[fileUrl], isPlaying: false }
    }));
  };

  return handlePause;
};

export default HandlePause;