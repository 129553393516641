const handleRecentSeek = (e, recentAudioRef, setRecentRecordingPlayback) => {
	if (recentAudioRef.current && !isNaN(recentAudioRef.current.duration) && recentAudioRef.current.duration > 0) {
		const progressBar = e.currentTarget;
		const rect = progressBar.getBoundingClientRect();
		const clickPosition = (e.clientX - rect.left) / rect.width;
		const seekTime = clickPosition * recentAudioRef.current.duration;
		
		if (isFinite(seekTime)) {
			recentAudioRef.current.currentTime = seekTime;
			setRecentRecordingPlayback(prev => ({
				...prev,
				currentTime: seekTime
			}));
		} else {
			console.error('Invalid seek time:', seekTime);
		}
	} else {
		console.error('Audio not ready for seeking');
	}
};

export default handleRecentSeek;